import React from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import CustomDataGrid from "../../commons/CustomDataGrid"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Grid } from "@material-ui/core"
import useFormDialogActions from "../../../hooks/useFormDialogActions"
import FormDialog from "../../commons/dialogs/FormDialog"
import FormationSessionsDetailTraineesCreateForm from "./FormationSessionsDetailTraineesCreateForm"

const useStyles = makeStyles((theme) => ({
    dataGrid: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}))

export const columns = [
    { field: "id", headerName: "ID", headerAlign: "left", flex: 1, hide: true },
    { field: "trainee_id", headerName: "TRAINER ID", headerAlign: "left", flex: 1, hide: true },
    { field: "first_name", headerName: "FIRST NAME", headerAlign: "left", flex: 1 },
    { field: "company", headerName: "COMPANY", headerAlign: "left", flex: 1 },
]

export function FormationSessionsDetailTraineesView({ trainees }) {
    const classes = useStyles()
    const history = useHistory()
    const { url } = useRouteMatch()
    const { open, handleClickOpen, handleClose } = useFormDialogActions()

    const rows = trainees.map((trainee, index) => ({
        ...trainee,
        id: index,
    }))

    const handleRowClick = (param) => {
        history.push(`${url}/trainees/${param.row.trainee_id}`)
    }

    return (
        <div className={classes.dataGrid}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <CustomDataGrid columns={columns} rows={rows} onRowClick={handleRowClick} />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleClickOpen} fullWidth>
                        ADD TRAINEE
                    </Button>
                </Grid>
            </Grid>
            <FormDialog title={"ADD TRAINEE"} open={open} handleClose={handleClose}>
                <FormationSessionsDetailTraineesCreateForm handleClose={handleClose} />
            </FormDialog>
        </div>
    )
}

FormationSessionsDetailTraineesView.propTypes = {
    trainees: PropTypes.any,
}
