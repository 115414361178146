import React from "react"
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import PropTypes from "prop-types"

export default function FormDialog({ title, open, handleClose, children }) {
    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>{children}</DialogContent>
            </Dialog>
        </div>
    )
}

FormDialog.propTypes = {
    title: PropTypes.any,
    open: PropTypes.any,
    handleClose: PropTypes.any,
    children: PropTypes.node,
}
