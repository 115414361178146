import React from "react"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core"
import { strToLocaleDateString } from "../../services/utils/utils"

export default function SurveysView() {
    const loading = useSelector((state) => state.loading)
    const error = useSelector((state) => state.error)
    const formationCategories = useSelector((state) => state.formationCategories)
    const formationSessions = useSelector((state) => state.formationSessions)
    const trainees = useSelector((state) => state.trainees)
    const sessionSurveys = useSelector((state) => state.sessionSurveys)

    const history = useHistory()
    const { url } = useRouteMatch()

    const handleRedirect = (sessionSurveyId) => {
        history.push(`${url}/${sessionSurveyId}`)
    }

    if (loading) return <p>Loading...</p>
    if (error) return <p>An error occurred...</p>

    const rows = sessionSurveys.map(
        ({ session_survey_id, name, formation_session, trainee: traineeUUID, answered }) => {
            const formationSession = formationSessions.find(
                (formationSession) => formationSession.id === formation_session
            )
            const formationCategory = formationCategories.find(
                (formationCategory) => formationCategory.id === formationSession.formation_category
            )
            const trainee = trainees.find((trainee) => trainee["trainee_id"] === traineeUUID)
            return {
                session_survey_id: session_survey_id,
                name: name,
                answered: answered,
                trainee_first_name: trainee?.first_name,
                trainee_last_name: trainee?.last_name,
                formation_category: formationCategory?.name,
                session_formation_starting_date: formationSession?.starting_date,
            }
        }
    )

    return (
        <Grid container>
            <Grid item>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Survey name</TableCell>
                                <TableCell align="right">Trainee first name</TableCell>
                                <TableCell align="right">trainee last name</TableCell>
                                <TableCell align="right">Formation category</TableCell>
                                <TableCell align="right">Starting date</TableCell>
                                <TableCell align="right">Answered</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(
                                ({
                                    session_survey_id,
                                    name,
                                    trainee_first_name,
                                    trainee_last_name,
                                    formation_category,
                                    session_formation_starting_date,
                                    answered,
                                }) => (
                                    <TableRow
                                        key={session_survey_id}
                                        onClick={() => handleRedirect(session_survey_id)}>
                                        <TableCell component="th" scope="row">
                                            {name}
                                        </TableCell>
                                        <TableCell align="right">{trainee_first_name}</TableCell>
                                        <TableCell align="right">{trainee_last_name}</TableCell>
                                        <TableCell align="right">{formation_category}</TableCell>
                                        <TableCell align="right">
                                            {strToLocaleDateString(session_formation_starting_date)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {answered ? "Yes" : "No"}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}
