const ENDPOINTS = {
    login: "api/v1/accounts/login/",
    signup: "api/v1/accounts/signup/",
    refresh: "api/v1/accounts/token/refresh/",
    verify: "api/v1/accounts/token/verify/",
    users: "api/v1/accounts/users/",
    formationCategories: "api/v1/formations/formation-categories/",
    formationSessions: "api/v1/formations/formation-sessions/",
    trainees: "api/v1/formations/trainees/",
    comments: "api/v1/surveys/comments/",
    grades: "api/v1/surveys/grades/",
    sessionSurveys: "api/v1/surveys/session-surveys/",
    annualSurveys: "api/v1/surveys/annual-surveys/",
    questionCategories: "api/v1/generals/question-categories/",
    questions: "api/v1/generals/questions/",
    questionGrades: "api/v1/generals/questions/",
    templateSurveys: "api/v1/generals/template-surveys/",
}

export default ENDPOINTS
