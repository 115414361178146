import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import SurveysView from "./SurveysView"
import SessionSurveysView from "./sessionSurveys/SessionSurveysView"
import AnnualSurveysView from "./annualSurveys/AnnualSurveysView"

export default function Surveys() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route path={`${path}/session-surveys`} exact>
                <SurveysView />
            </Route>
            <Route path={`${path}/session-surveys/:sessionSurveyId`} exact>
                <SessionSurveysView />
            </Route>
            <Route path={`${path}/annual-surveys/:annualSurveyId`} exact>
                <AnnualSurveysView />
            </Route>
        </Switch>
    )
}
