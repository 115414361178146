import React from "react"
import CustomAppBar from "../commons/CustomAppBar"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { CssBaseline } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    header: {
        paddingLeft: theme.spacing(3),
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(4),
        width: "100%",
    },
}))

export default function SurveysWrapper({ children }) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <CssBaseline />
            <CustomAppBar />
            <main className={classes.header}>{children}</main>
        </div>
    )
}

SurveysWrapper.propTypes = {
    children: PropTypes.node,
}
