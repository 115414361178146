import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import CssBaseline from "@material-ui/core/CssBaseline"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { List, ListItem, ListItemText } from "@material-ui/core"
import { handleLogOut } from "../../store/actions/isAuthed"
import CustomAppBar from "./CustomAppBar"

export const drawerWidth = 241

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    listItemText: {
        textTransform: "uppercase",
    },
}))

const drawerNavListTop = [
    {
        id: 1,
        title: "Home",
        path: "/",
    },
    // {
    //     id: 2,
    //     title: "Users",
    //     path: "/users",
    // },
    {
        id: 3,
        title: "Sessions",
        path: "/formations/formation-sessions",
    },
    // {
    //     id: 4,
    //     title: "Statistics",
    //     path: "/statistics",
    // },
    // {
    //     id: 5,
    //     title: "Generals",
    //     path: "/generals",
    // },
    {
        id: 4,
        title: "Surveys",
        path: "/surveys/session-surveys",
    },
]

export default function CustomDrawer({ children }) {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const isAuthed = useSelector((state) => state.isAuthed)
    const history = useHistory()
    const [open, setOpen] = useState(isAuthed)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleRedirect = (path) => {
        history.push(path)
    }

    const handleLogout = () => {
        dispatch(handleLogOut())
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <CustomAppBar handleDrawerOpen={handleDrawerOpen} open={open} />
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}>
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />

                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    style={{ height: "100%" }}>
                    <Grid item>
                        <List component="nav" aria-label="navigation bar top">
                            {drawerNavListTop.map(({ id, title, path }) => (
                                <ListItem key={id} onClick={() => handleRedirect(path)} button>
                                    <ListItemText
                                        className={classes.listItemText}
                                        primary={title}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item>
                        <List component="nav" aria-label="navigation bar bottom">
                            {/*<ListItem onClick={() => handleRedirect("/profile")} button>*/}
                            {/*    <ListItemText className={classes.listItemText} primary="Profile" />*/}
                            {/*</ListItem>*/}
                            <ListItem onClick={() => handleLogout("/logout")} button>
                                <ListItemText className={classes.listItemText} primary="Logout" />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}>
                <div className={classes.drawerHeader} />
                {children}
            </main>
        </div>
    )
}

CustomDrawer.propTypes = {
    children: PropTypes.node,
}
