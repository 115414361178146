export const ERROR = "ERROR"

function setError(error) {
    return {
        type: ERROR,
        error,
    }
}

export function showError(error) {
    return (dispatch) => {
        dispatch(setError(error))
    }
}

export function clearError() {
    return (dispatch) => {
        dispatch(setError(null))
    }
}
