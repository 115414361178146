import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { InputBase } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.success,
        border: "1px solid black",
        marginLeft: 0,
        width: "100%",
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
        width: "100%",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    },
}))

export default function SearchField({ placeHolder, handleSearch }) {
    const classes = useStyles()

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder={placeHolder}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearch}
            />
        </div>
    )
}

SearchField.propTypes = {
    placeHolder: PropTypes.any.isRequired,
    handleSearch: PropTypes.any.isRequired,
}
