import { httpDelete, httpGet, httpPost, httpPut } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"

export const SET_FORMATION_CATEGORIES = "SET_FORMATION_CATEGORIES"
export const ADD_FORMATION_CATEGORY = "ADD_FORMATION_CATEGORY"
export const UPDATE_FORMATION_CATEGORY = "UPDATE_FORMATION_CATEGORY"
export const DELETE_FORMATION_CATEGORY = "DELETE_FORMATION_CATEGORY"

function setFormationCategories(formationCategories) {
    return {
        type: SET_FORMATION_CATEGORIES,
        formationCategories,
    }
}

function addFormationCategory(formationCategory) {
    return {
        type: ADD_FORMATION_CATEGORY,
        formationCategory,
    }
}

function updateFormationCategory(formationCategory, id) {
    return {
        type: UPDATE_FORMATION_CATEGORY,
        formationCategory,
        id,
    }
}

function deleteFormationCategory(id) {
    return {
        type: DELETE_FORMATION_CATEGORY,
        id,
    }
}

export function handleGetFormationCategories() {
    return (dispatch) => {
        return httpGet(ENDPOINTS.formationCategories)
            .then(({ data }) => dispatch(setFormationCategories(data)))
            .catch(() => console.log("getFormationCategories(): An error occurred"))
    }
}

export function handleAddFormationCategory(formationCategory) {
    return (dispatch) => {
        return httpPost(ENDPOINTS.formationCategories, formationCategory)
            .then(({ data }) => dispatch(addFormationCategory(data)))
            .catch(() => console.log("addFormationCategories(): An error occurred"))
    }
}

export function handleUpdateFormationCategory(formationCategory, id) {
    return (dispatch) => {
        return httpPut(`${ENDPOINTS.formationCategories}${id}`, formationCategory)
            .then(({ data }) => dispatch(updateFormationCategory(data, id)))
            .catch(() => console.log("updateFormationCategory(): An error occurred"))
    }
}

export function handleDeleteFormationCategory(id) {
    return (dispatch) => {
        return httpDelete(`${ENDPOINTS.formationCategories}${id}`)
            .then(() => dispatch(deleteFormationCategory(id)))
            .catch(() => console.log("deleteFormationCategory(): An error occurred"))
    }
}
