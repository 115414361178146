import { unsafeHttpPost } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"

export const ADD_GRADE = "ADD_GRADE"

function addGrade(grade) {
    return {
        type: ADD_GRADE,
        grade,
    }
}

export function handleAddGrade(grade) {
    return (dispatch) => {
        return unsafeHttpPost(ENDPOINTS.grades, grade)
            .then(({ data }) => dispatch(addGrade(data)))
            .catch(() => console.log("handleAddGrade(): An error occurred"))
    }
}
