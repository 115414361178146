import axios from "axios"
import { LocalStorage } from "../localStorage"

export const TOKEN = "token.auth"

export function retrieveToken() {
    const token = LocalStorage.getItem(TOKEN)
    return token ? token.access : null
}

function makeHeaders() {
    return {
        Authorization: `Bearer ${retrieveToken()}`,
    }
}

function makeConfig() {
    return {
        headers: makeHeaders(),
    }
}

function makeUrl(url) {
    return `${process.env.REACT_APP_BASE_URL}/${url}`
}

export function httpGet(url) {
    return axios.get(makeUrl(url), {
        ...makeConfig(),
    })
}

export function unsafeHttpGet(url) {
    return axios.get(makeUrl(url))
}

export function unsafeHttpPost(url, body) {
    return axios.post(makeUrl(url), {
        ...body,
    })
}

export function httpPost(url, body) {
    return axios.post(
        makeUrl(url),
        {
            ...body,
        },
        {
            ...makeConfig(),
        }
    )
}

export function httpPut(url, body) {
    return axios.put(
        makeUrl(url),
        {
            ...body,
        },
        {
            ...makeConfig(),
        }
    )
}

export function httpDelete(url) {
    return axios.delete(makeUrl(url), {
        ...makeConfig(),
    })
}
