import React from "react"
import { Divider, Paper, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        minHeight: "80vh",
    },
    title: {
        paddingLeft: theme.spacing(2),
    },
}))

export default function CardContainer({ title, children }) {
    const classes = useStyles()

    return (
        <Paper className={classes.paper} variant="outlined" square>
            <Grid container direction="column" justify="center" spacing={2}>
                <Grid item>
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>{children}</Grid>
            </Grid>
        </Paper>
    )
}

CardContainer.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
}
