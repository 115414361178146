import React from "react"
import { useHistory, useParams } from "react-router-dom"
import QRCode from "qrcode.react"
import { useSelector } from "react-redux"
import { Button, Grid } from "@material-ui/core"

export default function TraineesDetail() {
    const sessionSurveys = useSelector((state) => state.sessionSurveys)
    const { traineeUUID } = useParams()
    const history = useHistory()

    const handleRedirect = (surveyId) => {
        history.push(`/surveys/session-surveys/${surveyId}`)
    }

    const traineeSurvey = sessionSurveys.find(
        (sessionSurvey) => sessionSurvey["trainee"] === traineeUUID
    )

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleRedirect(traineeSurvey["session_survey_id"])}>
                    Survey link
                </Button>
            </Grid>
            <Grid item>
                <QRCode
                    value={`${process.env.REACT_APP_SITE_URL}/surveys/session-surveys/${
                        traineeSurvey ? traineeSurvey["session_survey_id"] : undefined
                    }`}
                />
            </Grid>
        </Grid>
    )
}
