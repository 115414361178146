import {
    SET_FORMATION_CATEGORIES,
    ADD_FORMATION_CATEGORY,
    UPDATE_FORMATION_CATEGORY,
    DELETE_FORMATION_CATEGORY,
} from "../actions/formationCategories"

export default function formationCategories(state = [], action) {
    switch (action.type) {
        case SET_FORMATION_CATEGORIES:
            return action.formationCategories
        case ADD_FORMATION_CATEGORY:
            return [...state, action.formationCategory]
        case UPDATE_FORMATION_CATEGORY:
            return state.map((formationCategory) =>
                formationCategory.id !== action.id ? formationCategory : action.formationCategory
            )
        case DELETE_FORMATION_CATEGORY:
            return state.filter((formationCategory) => formationCategory.id !== action.id)
        default:
            return state
    }
}
