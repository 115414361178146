import { format } from "date-fns"
import React, { useState } from "react"
import { useFormik } from "formik"
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core"
import { KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { handleAddFormationSession } from "../../../store/actions/formationSessions"

const initialValues = {
    name: "React session",
    location: "Paris",
    company: "Enoki",
    trainer: "",
    formation_category: "",
    template_survey: "",
}

export default function FormationSessionsCreateForm() {
    const history = useHistory()
    const dispatch = useDispatch()
    const users = useSelector((state) => state.users)
    const formationCategories = useSelector((state) => state.formationCategories)
    const templateSurveys = useSelector((state) => state.templateSurveys)
    const [startingDate, setStartingDate] = useState(new Date())
    const [endingDate, setEndingDate] = useState(new Date())
    const [startingHour, setStartingHour] = useState(new Date())
    const [endingHour, setEndingHour] = useState(new Date())

    const handleStartingDateChange = (date) => {
        setStartingDate(date)
    }

    const handleEndingDateChange = (date) => {
        setEndingDate(date)
    }

    const handleStartingHourChange = (date) => {
        setStartingHour(date)
    }

    const handleEndingHourChange = (date) => {
        setEndingHour(date)
    }

    const handleRedirect = () => {
        history.push("/formations/formation-sessions")
    }

    const handleSubmit = (values) => {
        const formationSession = {
            ...values,
            starting_date: startingDate,
            ending_date: endingDate,
            starting_hour: format(startingHour, "hh:mm[:ss[.uuuuuu]]"),
            ending_hour: format(endingHour, "hh:mm[:ss[.uuuuuu]]"),
        }
        dispatch(handleAddFormationSession(formationSession))
        handleRedirect()
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: handleSubmit,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography>ADD FORMATION SESSION</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                required
                                fullWidth
                                id="name"
                                name="name"
                                label="Session name"
                                margin="normal"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth required margin="normal">
                                <InputLabel id="formation-category-select-label">
                                    Formation category
                                </InputLabel>
                                <Select
                                    labelId="formation-category-select-label"
                                    id="formation_category"
                                    name="formation_category"
                                    value={formik.values.formation_category}
                                    onChange={formik.handleChange}>
                                    <MenuItem value="">
                                        <em>Select a category</em>
                                    </MenuItem>
                                    {formationCategories.map((formationCategory) => (
                                        <MenuItem
                                            key={formationCategory.id}
                                            value={formationCategory.id}>
                                            {formationCategory.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <KeyboardDatePicker
                                        required
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="starting_date"
                                        label="Starting date"
                                        value={startingDate}
                                        onChange={handleStartingDateChange}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <KeyboardDatePicker
                                        required
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="ending_date"
                                        label="Ending date"
                                        value={endingDate}
                                        onChange={handleEndingDateChange}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <KeyboardTimePicker
                                        required
                                        margin="normal"
                                        id="starting_hour"
                                        label="Starting hour"
                                        value={startingHour}
                                        onChange={handleStartingHourChange}
                                        KeyboardButtonProps={{
                                            "aria-label": "change time",
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <KeyboardTimePicker
                                        required
                                        margin="normal"
                                        id="ending_hour"
                                        label="Ending hour"
                                        value={endingHour}
                                        onChange={handleEndingHourChange}
                                        KeyboardButtonProps={{
                                            "aria-label": "change time",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <TextField
                                        required
                                        fullWidth
                                        id="company"
                                        name="company"
                                        label="Company"
                                        margin="normal"
                                        value={formik.values.company}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.company && Boolean(formik.errors.company)
                                        }
                                        helperText={formik.touched.company && formik.errors.company}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        fullWidth
                                        id="location"
                                        name="location"
                                        label="Location"
                                        margin="normal"
                                        value={formik.values.location}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.location &&
                                            Boolean(formik.errors.location)
                                        }
                                        helperText={
                                            formik.touched.location && formik.errors.location
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth required margin="normal">
                                <InputLabel id="trainer-select-label">Trainer</InputLabel>
                                <Select
                                    labelId="trainer-select-label"
                                    id="trainer"
                                    name="trainer"
                                    value={formik.values.trainer}
                                    onChange={formik.handleChange}>
                                    <MenuItem value="">
                                        <em>Select a trainer</em>
                                    </MenuItem>
                                    {users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.username}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth required margin="normal">
                                <InputLabel id="template-survey-select-label">
                                    Template survey model
                                </InputLabel>
                                <Select
                                    labelId="template-survey-select-label"
                                    id="template_survey"
                                    name="template_survey"
                                    value={formik.values.template_survey}
                                    onChange={formik.handleChange}>
                                    <MenuItem value="">
                                        <em>Select a template</em>
                                    </MenuItem>
                                    {templateSurveys.map((templateSurvey) => (
                                        <MenuItem
                                            key={templateSurvey["survey_id"]}
                                            value={templateSurvey["survey_id"]}>
                                            {templateSurvey.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button color="primary" variant="outlined" onClick={handleRedirect}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" variant="contained" type="submit">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}
