import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import FormationSessionsView from "./formationSessions/FormationSessionsView"
import FormationSessionsCreateForm from "./formationSessions/FormationSessionsCreateForm"
import FormationSessionsDetail from "./formationSessions/FormationSessionsDetail"
import TraineesDetail from "./trainees/TraineesDetail"

export default function Formations() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route path={`${path}/formation-sessions`} exact>
                <FormationSessionsView />
            </Route>
            <Route path={`${path}/formation-sessions/create-formation-session`} exact>
                <FormationSessionsCreateForm />
            </Route>
            <Route path={`${path}/formation-sessions/:formationSessionId`} exact>
                <FormationSessionsDetail />
            </Route>
            <Route
                path={`${path}/formation-sessions/:formationSessionId/trainees/:traineeUUID`}
                exact>
                <TraineesDetail />
            </Route>
        </Switch>
    )
}
