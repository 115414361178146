import * as Yup from "yup"

const requiredMessage = "This field is required!"

export function loginValidationSchema() {
    return Yup.object({
        username: Yup.string().email("Invalid email address").required(requiredMessage),
        password: Yup.string().required(requiredMessage),
    })
}
