import { ERROR } from "../actions/error"

export default function error(state = null, action) {
    switch (action.type) {
        case ERROR:
            return action.error
        default:
            return state
    }
}
