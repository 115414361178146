import { unsafeHttpGet } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"

export const SET_QUESTION_CATEGORIES = "SET_QUESTION_CATEGORIES"

function setQuestionCategories(questionCategories) {
    return {
        type: SET_QUESTION_CATEGORIES,
        questionCategories,
    }
}

export function handleGetQuestionCategories(templateSurveyUUID = "") {
    const q = templateSurveyUUID ? `?surveys=${templateSurveyUUID}` : ""
    return (dispatch) => {
        return unsafeHttpGet(`${ENDPOINTS.questionCategories}${q}`)
            .then(({ data }) => dispatch(setQuestionCategories(data)))
            .catch(() => console.log("handleGetQuestionCategories(): An error occurred"))
    }
}
