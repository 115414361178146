import { unsafeHttpGet } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"

export const SET_QUESTIONS = "SET_QUESTIONS"

function setQuestions(questions) {
    return {
        type: SET_QUESTIONS,
        questions,
    }
}

export function handleGetQuestions(param = []) {
    const q = param ? `?question_categories=[${param}]` : ""
    return (dispatch) => {
        return unsafeHttpGet(`${ENDPOINTS.questions}${q}`)
            .then(({ data }) => dispatch(setQuestions(data)))
            .catch(() => console.log("handleGetQuestions(): An error occurred"))
    }
}
