import { httpGet } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"

export const SET_USERS = "SET_USERS"

function setUsers(users) {
    return {
        type: SET_USERS,
        users,
    }
}

export function handleGetUsers() {
    return (dispatch) => {
        return httpGet(ENDPOINTS.users)
            .then(({ data }) => dispatch(setUsers(data)))
            .catch(() => console.log("setUsers(): An error occurred."))
    }
}
