import { ADD_TRAINEE, SET_TRAINEE, SET_TRAINEES } from "../actions/trainees"

export default function trainees(state = [], action) {
    switch (action.type) {
        case SET_TRAINEES:
            return action.trainees
        case SET_TRAINEE:
            return [action.trainee]
        case ADD_TRAINEE:
            return [...state, action.trainee]
        default:
            return state
    }
}
