import { SET_TEMPLATE_SURVEYS } from "../actions/templateSurveys"

export default function templateSurveys(state = [], action) {
    switch (action.type) {
        case SET_TEMPLATE_SURVEYS:
            return action.templateSurveys
        default:
            return state
    }
}
