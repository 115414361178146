import { httpDelete, httpGet, httpPost, httpPut, unsafeHttpGet } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"

export const SET_FORMATION_SESSIONS = "SET_FORMATION_SESSIONS"
export const SET_FORMATION_SESSION = "SET_FORMATION_SESSION"
export const ADD_FORMATION_SESSION = "ADD_FORMATION_SESSION"
export const UPDATE_FORMATION_SESSION = "UPDATE_FORMATION_SESSION"
export const DELETE_FORMATION_SESSION = "DELETE_FORMATION_SESSION"

function setFormationSessions(formationSessions) {
    return {
        type: SET_FORMATION_SESSIONS,
        formationSessions,
    }
}

function setFormationSession(formationSession) {
    return {
        type: SET_FORMATION_SESSION,
        formationSession,
    }
}

function addFormationSession(formationSession) {
    return {
        type: ADD_FORMATION_SESSION,
        formationSession,
    }
}

function updateFormationSession(formationSession, id) {
    return {
        type: UPDATE_FORMATION_SESSION,
        formationSession,
        id,
    }
}

function deleteFormationSession(id) {
    return {
        type: DELETE_FORMATION_SESSION,
        id,
    }
}

export function handleGetFormationSessions() {
    return (dispatch) => {
        return httpGet(ENDPOINTS.formationSessions)
            .then(({ data }) => dispatch(setFormationSessions(data)))
            .catch(() => console.log("getFormationSessions(): An error occurred"))
    }
}

export function handleGetFormationSession(formationSessionId) {
    return (dispatch) => {
        return unsafeHttpGet(`${ENDPOINTS.formationSessions}${formationSessionId}/`)
            .then(({ data }) => dispatch(setFormationSession(data)))
            .catch(() => console.log("getFormationSessions(): An error occurred"))
    }
}

export function handleAddFormationSession(formationSession) {
    return (dispatch) => {
        return httpPost(ENDPOINTS.formationSessions, formationSession)
            .then(({ data }) => dispatch(addFormationSession(data)))
            .catch(() => console.log("addFormationSessions(): An error occurred"))
    }
}

export function handleUpdateFormationSession(formationSession, id) {
    return (dispatch) => {
        return httpPut(`${ENDPOINTS.formationSessions}${id}`, formationSession)
            .then(({ data }) => dispatch(updateFormationSession(data, id)))
            .catch(() => console.log("updateFormationSession(): An error occurred"))
    }
}

export function handleDeleteFormationSession(id) {
    return (dispatch) => {
        return httpDelete(`${ENDPOINTS.formationSessions}${id}`)
            .then(() => dispatch(deleteFormationSession(id)))
            .catch(() => console.log("deleteFormationSession(): An error occurred"))
    }
}
