import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import { Typography } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { handleGetSessionSurveyInitialData } from "../../../store/actions/sessionSurveys"
import { handleGetQuestions } from "../../../store/actions/questions"
import { handleAddGrade } from "../../../store/actions/grades"
import { handleAddComment } from "../../../store/actions/comments"
import { strToLocaleDateString } from "../../../services/utils/utils"
import SurveyHome from "../SurveyHome"
import SurveyEvaluation from "../SurveyEvaluation"
import SurveyComment from "../SurveyComment"

const STEPS = {
    HOME: 0,
    EVALUATION: 1,
    COMMENT: 2,
    END: 3,
}

function getMapGradesToQuestions(questions) {
    return questions.map((question) => ({
        ...question,
        grade: 100,
    }))
}

export default function SessionSurveysView() {
    // TODO: Only useFetch here with some awaits => No need to store them in the store
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.loading)
    const error = useSelector((state) => state.error)
    const formationSession = useSelector((state) => state.formationSessions)[0]
    const trainee = useSelector((state) => state.trainees)[0]
    const questionCategories = useSelector((state) => state.questionCategories)
    const questions = useSelector((state) => state.questions)
    const { sessionSurveyId } = useParams()
    const [activeStep, setActiveStep] = useState(STEPS.HOME)
    const [mapGradesToQuestions, setMapGradesToQuestions] = useState([])

    useEffect(() => {
        dispatch(handleGetSessionSurveyInitialData(sessionSurveyId))
    }, [dispatch])

    useEffect(() => {
        if (!error) {
            const ids = questionCategories.map(({ id }) => id)
            dispatch(handleGetQuestions(ids))
        }
    }, [dispatch, questionCategories, error])

    useEffect(() => {
        setMapGradesToQuestions(getMapGradesToQuestions(questions))
    }, [questions])

    const handleGradeChange = (e, value, questionId) => {
        setMapGradesToQuestions((prevState) => {
            return prevState.map((questionToGrade) =>
                questionToGrade.id !== questionId
                    ? questionToGrade
                    : {
                          ...questionToGrade,
                          grade: value,
                      }
            )
        })
    }

    const handleEvaluation = () => {
        const grades = mapGradesToQuestions.map(({ id, grade }) => {
            return {
                grade: grade,
                question: id,
                session_survey: sessionSurveyId,
            }
        })
        dispatch(handleAddGrade(grades))
        handleNext()
    }

    const handleNext = () => {
        setActiveStep((prevState) => prevState + 1)
    }

    const handleComputeAverage = () => {
        const total = mapGradesToQuestions.reduce((accu, curr) => accu + curr.grade, 0)
        const average = total / 10 / mapGradesToQuestions.length

        return {
            average,
            length: 10,
        }
    }

    const handleComment = (values) => {
        if (values.comment) {
            const comment = {
                ...values,
                session_survey: sessionSurveyId,
            }
            dispatch(handleAddComment(comment))
        }
        handleNext()
    }

    if (loading) return <p>Loading...</p>
    if (error) return <p>Survey already answered...</p>

    return (
        <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid item>
                <Typography>
                    FORMATION: {formationSession?.name} FROM{" "}
                    {strToLocaleDateString(formationSession?.starting_date)} TO{" "}
                    {strToLocaleDateString(formationSession?.ending_date)} AT{" "}
                    {formationSession?.location}
                </Typography>
            </Grid>
            <Grid item>
                {activeStep === STEPS.HOME && (
                    <SurveyHome
                        formationSession={formationSession}
                        trainee={trainee}
                        handleNext={handleNext}
                    />
                )}
            </Grid>
            <Grid item>
                {activeStep === STEPS.EVALUATION && (
                    <SurveyEvaluation
                        questionCategories={questionCategories}
                        mapGradesToQuestions={mapGradesToQuestions}
                        handleGradeChange={handleGradeChange}
                        handleEvaluation={handleEvaluation}
                    />
                )}
            </Grid>
            <Grid item>
                {activeStep === STEPS.COMMENT && (
                    <SurveyComment
                        handleComment={handleComment}
                        handleComputeAverage={handleComputeAverage}
                    />
                )}
            </Grid>
            <Grid item>
                {activeStep === STEPS.END && (
                    <Typography variant="h5" color="secondary">
                        Thank you.
                    </Typography>
                )}
            </Grid>
        </Grid>
    )
}
