import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"

import { createStore } from "redux"
import { Provider } from "react-redux"

import reducer from "./store/reducers"
import middleware from "./store/middleware"

import "./static/index.css"
import App from "./App"

import axios from "axios"

axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.xsrfHeaderName = "X-CSRFToken"

const store = createStore(reducer, middleware)

require("dotenv").config()

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
)
