import { httpPost, retrieveToken, TOKEN } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"
import { LocalStorage } from "../../services/localStorage"
import { hideLoading, showLoading } from "./loading"

export const SET_IS_AUTHED = "SET_IS_AUTHED"

export function setIsAuthed(isAuthed) {
    return {
        type: SET_IS_AUTHED,
        isAuthed,
    }
}

export function handleLogin(values) {
    return (dispatch) => {
        return httpPost(ENDPOINTS.login, values)
            .then(({ data }) => LocalStorage.setItem(TOKEN, data))
            .then(() => dispatch(setIsAuthed(true)))
            .catch(() => dispatch(setIsAuthed(false)))
    }
}

export function handleTokenVerification() {
    return (dispatch) => {
        dispatch(showLoading())
        return httpPost(ENDPOINTS.verify, {
            token: retrieveToken(),
        })
            .then(() => dispatch(setIsAuthed(true)))
            .then(() => dispatch(hideLoading()))
            .catch(() => {
                dispatch(setIsAuthed(false))
                dispatch(hideLoading())
            })
    }
}

export function handleLogOut() {
    return (dispatch) => {
        LocalStorage.removeItem(TOKEN)
        dispatch(setIsAuthed(false))
    }
}
