import Grid from "@material-ui/core/Grid"
import { Button, TextField } from "@material-ui/core"
import React from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"

export default function SurveyComment({ handleComment, handleComputeAverage }) {
    const { average, length } = handleComputeAverage()

    return (
        <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid item>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>Thank you for answering the satisfaction survey</Grid>
                    <Grid item>You gave us a grade of {`${average} / ${length}`}</Grid>
                    <Grid item>An idea, a comment</Grid>
                </Grid>
            </Grid>
            <Grid item>
                <CommentForm handleComment={handleComment} />
            </Grid>
        </Grid>
    )
}

SurveyComment.propTypes = {
    handleComment: PropTypes.any.isRequired,
    handleComputeAverage: PropTypes.any.isRequired,
}

function CommentForm({ handleComment }) {
    const formik = useFormik({
        initialValues: {
            comment: "",
        },
        onSubmit: handleComment,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item>
                    <TextField
                        id="comment"
                        name="comment"
                        label="Comment"
                        multiline
                        rows={4}
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" type="submit">
                        Send
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

CommentForm.propTypes = {
    handleComment: PropTypes.any.isRequired,
}
