import React from "react"
import Grid from "@material-ui/core/Grid"
import { Button, Slider, Typography } from "@material-ui/core"
import PropTypes from "prop-types"

const marks = [
    {
        value: 0,
        label: "Insufficient",
    },
    {
        value: 50,
        label: "Sufficient",
    },
    {
        value: 100,
        label: "Very Good",
    },
]

function valuetext(value) {
    return `${value}`
}

export default function SurveyEvaluation({
    questionCategories,
    mapGradesToQuestions,
    handleGradeChange,
    handleEvaluation,
}) {
    return (
        <Grid container direction="column" alignItems="center" spacing={8}>
            <Grid item>
                {questionCategories.map(({ id, name }) => {
                    const qs = mapGradesToQuestions.filter((question) => question.category === id)
                    return (
                        <Grid key={id} container direction="column" alignItems="center" spacing={4}>
                            <Grid item>
                                <Typography key={id} variant="h6">
                                    {name}
                                </Typography>
                            </Grid>
                            {qs.map(({ id: qsId, question, grade }) => {
                                return (
                                    <Grid item key={`${question}${qsId}`}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            spacing={2}>
                                            <Grid item>{question}</Grid>
                                            <Grid item>
                                                <Slider
                                                    style={{ width: 300 }}
                                                    value={grade}
                                                    getAriaValueText={valuetext}
                                                    aria-labelledby="discrete-slider-restrict"
                                                    // step={null}
                                                    valueLabelDisplay="off"
                                                    marks={marks}
                                                    onChange={(e, value) =>
                                                        handleGradeChange(e, value, qsId)
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )
                })}
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" onClick={handleEvaluation}>
                    Finish
                </Button>
            </Grid>
        </Grid>
    )
}

SurveyEvaluation.propTypes = {
    questionCategories: PropTypes.any.isRequired,
    mapGradesToQuestions: PropTypes.any.isRequired,
    handleGradeChange: PropTypes.any.isRequired,
    handleEvaluation: PropTypes.any.isRequired,
}
