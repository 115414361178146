import { handleGetFormationCategories } from "./formationCategories"
import { handleGetFormationSessions } from "./formationSessions"
import { handleGetUsers } from "./users"
import { handleGetTemplateSurveys } from "./templateSurveys"
import { handleGetSessionSurveys } from "./sessionSurveys"
import { handleGetTrainees } from "./trainees"

export function handleGetInitialData() {
    return (dispatch) => {
        dispatch(handleGetFormationCategories())
        dispatch(handleGetFormationSessions())
        dispatch(handleGetUsers())
        dispatch(handleGetTemplateSurveys())
        dispatch(handleGetSessionSurveys())
        dispatch(handleGetTrainees())
    }
}
