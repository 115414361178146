import {
    SET_FORMATION_SESSIONS,
    SET_FORMATION_SESSION,
    ADD_FORMATION_SESSION,
    UPDATE_FORMATION_SESSION,
    DELETE_FORMATION_SESSION,
} from "../actions/formationSessions"

export default function formationSessions(state = [], action) {
    switch (action.type) {
        case SET_FORMATION_SESSIONS:
            return action.formationSessions
        case SET_FORMATION_SESSION:
            return [action.formationSession]
        case ADD_FORMATION_SESSION:
            return [...state, action.formationSession]
        case UPDATE_FORMATION_SESSION:
            return state.map((formationSession) =>
                formationSession.id !== action.id ? formationSession : action.formationSession
            )
        case DELETE_FORMATION_SESSION:
            return state.filter((formationSession) => formationSession.id !== action.id)
        default:
            return state
    }
}
