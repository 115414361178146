import { SET_QUESTION_CATEGORIES } from "../actions/questionCategories"

export default function questionCategories(state = [], action) {
    switch (action.type) {
        case SET_QUESTION_CATEGORIES:
            return action.questionCategories
        default:
            return state
    }
}
