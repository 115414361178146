import React from "react"
import { DataGrid } from "@material-ui/data-grid"
import PropTypes from "prop-types"

export default function CustomDataGrid({ columns, rows, onRowClick }) {
    return (
        <div style={{ height: 400, width: "100%" }}>
            <DataGrid
                columns={columns}
                rows={rows}
                onRowClick={onRowClick}
                rowsPerPageOptions={[]}
            />
        </div>
    )
}

CustomDataGrid.propTypes = {
    columns: PropTypes.any.isRequired,
    rows: PropTypes.any.isRequired,
    onRowClick: PropTypes.any,
}
