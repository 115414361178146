import React from "react"
import Grid from "@material-ui/core/Grid"
import { Button } from "@material-ui/core"
import PropTypes from "prop-types"

export default function SurveyHome({ trainee, handleNext }) {
    return (
        <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid item>
                Good morning M. {trainee?.last_name} <br />
                Fill in the following form
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" onClick={handleNext}>
                    Start
                </Button>
            </Grid>
        </Grid>
    )
}

SurveyHome.propTypes = {
    trainee: PropTypes.any.isRequired,
    handleNext: PropTypes.any.isRequired,
}
