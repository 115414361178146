import { ADD_GRADE } from "../actions/grades"

export default function grades(state = [], action) {
    switch (action.type) {
        case ADD_GRADE:
            return [...state, action.grade]
        default:
            return state
    }
}
