import { httpGet, httpPost, unsafeHttpGet } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"
import { handleGetSessionSurveys } from "./sessionSurveys"

export const SET_TRAINEES = "SET_TRAINEES"
export const SET_TRAINEE = "SET_TRAINEE"
export const ADD_TRAINEE = "ADD_TRAINEE"

function setTrainees(trainees) {
    return {
        type: SET_TRAINEES,
        trainees,
    }
}

function setTrainee(trainee) {
    return {
        type: SET_TRAINEE,
        trainee,
    }
}

function addTrainee(trainee) {
    return {
        type: ADD_TRAINEE,
        trainee,
    }
}

export function handleGetTrainees(param = "") {
    const q = param ? `?formation_session=${param}` : ""
    return (dispatch) => {
        return httpGet(`${ENDPOINTS.trainees}${q}`)
            .then(({ data }) => dispatch(setTrainees(data)))
            .catch(() => console.log("handleGetTrainees(): An error occurred"))
    }
}

export function handleGetTrainee(traineeUUID) {
    return (dispatch) => {
        return unsafeHttpGet(`${ENDPOINTS.trainees}${traineeUUID}/`)
            .then(({ data }) => dispatch(setTrainee(data)))
            .catch(() => console.log("handleGetTrainees(): An error occurred"))
    }
}

export function handleAddTrainee(trainee) {
    return (dispatch) => {
        return httpPost(ENDPOINTS.trainees, trainee)
            .then(({ data }) => dispatch(addTrainee(data)))
            .then(() => dispatch(handleGetSessionSurveys()))
            .catch(() => console.log("handleAddTrainee(): An error occurred"))
    }
}
