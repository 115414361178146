import React from "react"
import Grid from "@material-ui/core/Grid"
import { Avatar, Typography } from "@material-ui/core"
import { strToLocaleDateString } from "../../../services/utils/utils"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { deepOrange } from "@material-ui/core/colors"

const useStyles = makeStyles((theme) => ({
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
}))

export function FormationSessionsDetailInfo({ formationSession }) {
    const classes = useStyles()
    return (
        <Grid container direction="column" justify="space-between" alignItems="center" spacing={4}>
            <Grid item>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>FORMATION</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{formationSession?.name}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {strToLocaleDateString(formationSession?.starting_date)} -{" "}
                            {strToLocaleDateString(formationSession?.ending_date)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            {formationSession?.starting_hour} - {formationSession?.ending_hour}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{formationSession?.location}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>X trainees</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography>TRAINER</Typography>
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.orange}>AB</Avatar>
                    </Grid>
                    <Grid item>
                        <Typography>{formationSession?.trainer}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

FormationSessionsDetailInfo.propTypes = {
    formationSession: PropTypes.any,
}
