import { unsafeHttpPost } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"

export const ADD_COMMENT = "ADD_COMMENT"

function addComment(comment) {
    return {
        type: ADD_COMMENT,
        comment,
    }
}

export function handleAddComment(comment) {
    return (dispatch) => {
        return unsafeHttpPost(ENDPOINTS.comments, comment)
            .then(({ data }) => dispatch(addComment(data)))
            .catch(() => console.log("handleAddComment(): An error occurred"))
    }
}
