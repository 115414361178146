import React, { useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom"
import { useFormik } from "formik"
import { Button, TextField } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { loginValidationSchema } from "../../validation/schemas"
import { useDispatch, useSelector } from "react-redux"
import { handleLogin } from "../../store/actions/isAuthed"
import { handleGetInitialData } from "../../store/actions/commons"

export default function Login() {
    const dispatch = useDispatch()
    const isAuthed = useSelector((state) => state.isAuthed)
    const loading = useSelector((state) => state.loading)
    const history = useHistory()
    const { from } = history.location.state || { from: { pathname: "/" } }

    useEffect(() => {
        if (isAuthed) {
            dispatch(handleGetInitialData())
        }
    }, [dispatch, isAuthed])

    const handleSubmit = (values) => {
        dispatch(handleLogin(values))
    }

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: loginValidationSchema(),
        onSubmit: handleSubmit,
    })

    if (isAuthed) return <Redirect to={from.pathname} />
    if (loading) return <p>Loading...</p>

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Grid item>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item>
                            <TextField
                                error={formik.touched.username && formik.errors.username && true}
                                helperText={
                                    formik.touched.username &&
                                    formik.errors.username &&
                                    formik.errors.username
                                }
                                id="username"
                                name="username"
                                type="email"
                                label="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.username}
                                variant="outlined"
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                error={formik.touched.password && formik.errors.password && true}
                                helperText={
                                    formik.touched.password &&
                                    formik.errors.password &&
                                    formik.errors.password
                                }
                                id="password"
                                name="password"
                                type="password"
                                label="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                variant="outlined"
                                margin="normal"
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Connexion
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
