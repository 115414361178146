import { httpGet, unsafeHttpGet } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"
import { hideLoading, showLoading } from "./loading"
import { clearError, showError } from "./error"
import { handleGetTrainee } from "./trainees"
import { handleGetQuestionCategories } from "./questionCategories"
import { handleGetFormationSession } from "./formationSessions"

export const SET_SESSION_SURVEYS = "SET_SESSION_SURVEYS"
export const SET_SESSION_SURVEY = "SET_SESSION_SURVEY"

function setSessionSurveys(sessionSurveys) {
    return {
        type: SET_SESSION_SURVEYS,
        sessionSurveys,
    }
}

function setSessionSurvey(sessionSurvey) {
    return {
        type: SET_SESSION_SURVEY,
        sessionSurvey,
    }
}

export function handleGetSessionSurveys() {
    return (dispatch) => {
        dispatch(showLoading())
        dispatch(clearError())
        return httpGet(ENDPOINTS.sessionSurveys)
            .then(({ data }) => dispatch(setSessionSurveys(data)))
            .then(() => dispatch(hideLoading()))
            .catch((e) => {
                dispatch(hideLoading())
                dispatch(showError(e))
            })
    }
}

export function handleGetSessionSurveyInitialData(sessionSurveyUUID) {
    return (dispatch) => {
        dispatch(showLoading())
        dispatch(clearError())
        return unsafeHttpGet(`${ENDPOINTS.sessionSurveys}${sessionSurveyUUID}/`)
            .then(({ data }) => {
                dispatch(setSessionSurvey(data))
                dispatch(handleGetTrainee(data.trainee))
                dispatch(handleGetQuestionCategories(data.template_survey))
                dispatch(handleGetFormationSession(data.formation_session))
            })
            .then(() => dispatch(hideLoading()))
            .catch((e) => {
                dispatch(hideLoading())
                dispatch(showError(`${e.response.status}: Survey already answered.`))
            })
    }
}
