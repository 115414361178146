export const LOADING = "LOADING"

function setLoading(loading) {
    return {
        type: LOADING,
        loading,
    }
}

export function showLoading() {
    return (dispatch) => {
        dispatch(setLoading(true))
    }
}

export function hideLoading() {
    return (dispatch) => {
        dispatch(setLoading(false))
    }
}
