import React from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import Grid from "@material-ui/core/Grid"
import CardContainer from "../commons/CardContainer"
import { FormationSessionsDetailTraineesView } from "./FormationSessionsDetailTraineesView"
import { FormationSessionsDetailInfo } from "./FormationSessionsDetailInfo"

export default function FormationSessionsDetail() {
    const { formationSessionId } = useParams()
    const formationSessions = useSelector((state) => state.formationSessions)
    const users = useSelector((state) => state.users)
    const trainees = useSelector((state) => state.trainees)

    const formationSession = formationSessions
        .filter((formationSession) => formationSession.id === parseInt(formationSessionId))
        .map((formationSession) => ({
            ...formationSession,
            trainer: users.find((user) => user.id === formationSession.trainer)?.username,
        }))[0]

    const filteredTrainees = trainees.filter(
        (trainee) => trainee.formation_session === parseInt(formationSessionId)
    )

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
                <CardContainer title={"FORMATION SESSION"}>
                    <FormationSessionsDetailInfo formationSession={formationSession} />
                </CardContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <CardContainer title={"TRAINEES - SIGNATURE"}>
                    <FormationSessionsDetailTraineesView trainees={filteredTrainees} />
                </CardContainer>
            </Grid>
        </Grid>
    )
}
