import React from "react"
import clsx from "clsx"
import Toolbar from "@material-ui/core/Toolbar"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Typography from "@material-ui/core/Typography"
import AppBar from "@material-ui/core/AppBar"
import { makeStyles } from "@material-ui/core/styles"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { Avatar } from "@material-ui/core"
import logo from "../../static/cecys.webp"

const drawerWidth = 241

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
}))

export default function CustomAppBar({ handleDrawerOpen, open }) {
    const classes = useStyles()
    const history = useHistory()
    const isAuthed = useSelector((state) => state.isAuthed)

    const handleRedirect = () => {
        history.push("/")
    }

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}>
            <Toolbar>
                <Grid container justify="space-between" alignItems="center">
                    {isAuthed && (
                        <Grid item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, open && classes.hide)}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                    )}
                    <Grid item>
                        <Typography onClick={handleRedirect}>CECYS SATISFACTION</Typography>
                    </Grid>
                    <Grid item>
                        <Avatar src={logo} style={{ width: 100 }} />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

CustomAppBar.propTypes = {
    handleDrawerOpen: PropTypes.any,
    open: PropTypes.any,
}
