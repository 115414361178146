import { SET_IS_AUTHED } from "../actions/isAuthed"

export default function isAuthed(state = false, action) {
    switch (action.type) {
        case SET_IS_AUTHED:
            return action.isAuthed
        default:
            return state
    }
}
