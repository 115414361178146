import { SET_SESSION_SURVEY, SET_SESSION_SURVEYS } from "../actions/sessionSurveys"

export default function sessionSurveys(state = [], action) {
    switch (action.type) {
        case SET_SESSION_SURVEYS:
            return action.sessionSurveys
        case SET_SESSION_SURVEY:
            return [action.sessionSurvey]
        default:
            return state
    }
}
