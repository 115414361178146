import React from "react"
import { useParams } from "react-router-dom"
import { useFormik } from "formik"
import { Button, Grid, TextField } from "@material-ui/core"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { handleAddTrainee } from "../../../store/actions/trainees"

const initialValues = {
    last_name: "",
    first_name: "",
    company: "",
    email: "",
}

export default function FormationSessionsDetailTraineesCreateForm({ handleClose }) {
    const { formationSessionId } = useParams()
    const dispatch = useDispatch()

    const handleSubmit = (values) => {
        const trainee = {
            ...values,
            formation_session: parseInt(formationSessionId),
        }
        dispatch(handleAddTrainee(trainee))
        handleClose()
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: handleSubmit,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <TextField
                                        required
                                        fullWidth
                                        id="first_name"
                                        name="first_name"
                                        label="First name"
                                        margin="normal"
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.first_name &&
                                            Boolean(formik.errors.first_name)
                                        }
                                        helperText={
                                            formik.touched.first_name && formik.errors.first_name
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        fullWidth
                                        id="last_name"
                                        name="last_name"
                                        label="Last name"
                                        margin="normal"
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.last_name &&
                                            Boolean(formik.errors.last_name)
                                        }
                                        helperText={
                                            formik.touched.last_name && formik.errors.last_name
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <TextField
                                        required
                                        fullWidth
                                        id="company"
                                        name="company"
                                        label="Company"
                                        margin="normal"
                                        value={formik.values.company}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.company && Boolean(formik.errors.company)
                                        }
                                        helperText={formik.touched.company && formik.errors.company}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email"
                                        margin="normal"
                                        type="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button color="primary" variant="outlined" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" variant="contained" type="submit">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

FormationSessionsDetailTraineesCreateForm.propTypes = {
    handleClose: PropTypes.any,
}
