import { combineReducers } from "redux"
import loading from "./loading"
import error from "./error"
import isAuthed from "./isAuthed"
import users from "./users"
import formationCategories from "./formationCategories"
import formationSessions from "./formationSessions"
import templateSurveys from "./templateSurveys"
import trainees from "./trainees"
import sessionSurveys from "./sessionSurveys"
import questionCategories from "./questionCategories"
import questions from "./questions"
import grades from "./grades"
import comments from "./comments"

export default combineReducers({
    loading,
    error,
    isAuthed,
    users,
    formationCategories,
    formationSessions,
    templateSurveys,
    trainees,
    sessionSurveys,
    questionCategories,
    questions,
    grades,
    comments,
})
