import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { handleTokenVerification } from "../../store/actions/isAuthed"

export default function PrivateRoute({ children, ...rest }) {
    const dispatch = useDispatch()
    const isAuthed = useSelector((state) => state.isAuthed)

    useEffect(() => {
        dispatch(handleTokenVerification())
    }, [dispatch])

    return (
        <Route
            {...rest}
            render={({ location }) => {
                return isAuthed ? (
                    children
                ) : (
                    <Redirect to={{ pathname: "/login", state: { from: location } }} />
                )
            }}
        />
    )
}

PrivateRoute.propTypes = {
    children: PropTypes.node,
}
