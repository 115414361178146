import { isWithinInterval, isSameDay } from "date-fns"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Grid, Paper, Tab, Tabs } from "@material-ui/core"
import { useSelector } from "react-redux"
import SearchField from "../../commons/fields/SearchField"
import CustomDataGrid from "../../commons/CustomDataGrid"
import { strToDate, strToLocaleDateString } from "../../../services/utils/utils"

const columns = [
    { field: "id", headerName: "ID", headerAlign: "left", flex: 1, hide: true },
    { field: "name", headerName: "FORMATION NAME", headerAlign: "left", flex: 1 },
    { field: "formation_category", headerName: "FORMATION CATEGORY", headerAlign: "left", flex: 1 },
    { field: "location", headerName: "LOCATION", headerAlign: "left", flex: 1 },
    {
        field: "starting_date",
        headerName: "STARTING DATE",
        headerAlign: "left",
        flex: 1,
        valueFormatter: (params) => strToLocaleDateString(params.value),
    },
    {
        field: "ending_date",
        headerName: "ENDING DATE",
        headerAlign: "left",
        flex: 1,
        valueFormatter: (params) => strToLocaleDateString(params.value),
    },
    { field: "trainer", headerName: "TRAINER", headerAlign: "left", flex: 1 },
]

const SESSIONS_TAB = {
    DAILY_SESSIONS: 1,
    ALL_SESSIONS: 2,
}

export default function FormationSessionsView() {
    const history = useHistory()
    // TODO: UseRouteMatch in the real app => right now Home = Sessions => using it fails
    // const { url } = useRouteMatch()
    const formationSessions = useSelector((state) => state.formationSessions)
    const formationCategories = useSelector((state) => state.formationCategories)
    // TODO: Modify or create new endpoint to get ONLY the list of trainers
    const users = useSelector((state) => state.users)
    const [searchQuery, setSearchQuery] = useState("")
    const [activeTab, setActiveTab] = useState(SESSIONS_TAB.DAILY_SESSIONS)

    const handleSearch = (e) => {
        setSearchQuery(e.target.value)
    }

    const handleRowClick = (param) => {
        history.push(`/formations/formation-sessions/${param.row.id}`)
    }

    const handleRedirect = () => {
        history.push(`/formations/formation-sessions/create-formation-session`)
    }

    const handleActiveTabChange = (e, value) => {
        setActiveTab(value)
    }

    const rows = formationSessions
        .map((formationSession) => {
            const formationCategory = formationCategories.find(
                (formationCategory) => formationCategory.id === formationSession.formation_category
            )
            const trainer = users.find((user) => user.id === formationSession.trainer)
            return {
                ...formationSession,
                formation_category: formationCategory?.name,
                trainer: trainer?.username,
            }
        })
        .filter((formationSession) => {
            const today = new Date()
            const startingDate = strToDate(formationSession["starting_date"])
            const endingDate = strToDate(formationSession["ending_date"])
            return activeTab === SESSIONS_TAB.DAILY_SESSIONS
                ? isWithinInterval(today, { start: startingDate, end: endingDate }) ||
                      isSameDay(today, startingDate)
                : true
        })
        .filter((formationSession) =>
            formationSession.name.toUpperCase().includes(searchQuery.toUpperCase())
        )

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <SearchField
                    placeHolder="Search by formation session name"
                    handleSearch={handleSearch}
                />
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Grid item>
                        <Paper square>
                            <Tabs
                                value={activeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleActiveTabChange}
                                aria-label="Sessions active tab">
                                <Tab label="DAILY SESSIONS" value={SESSIONS_TAB.DAILY_SESSIONS} />
                                <Tab label="ALL SESSIONS" value={SESSIONS_TAB.ALL_SESSIONS} />
                            </Tabs>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <CustomDataGrid columns={columns} rows={rows} onRowClick={handleRowClick} />
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" fullWidth onClick={handleRedirect}>
                    ADD FORMATION SESSION
                </Button>
            </Grid>
        </Grid>
    )
}
