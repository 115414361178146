import React, { useMemo } from "react"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { Redirect, Route, Switch } from "react-router-dom"
import { ThemeProvider, createMuiTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CssBaseline from "@material-ui/core/CssBaseline"
import CustomDrawer from "./components/commons/CustomDrawer"
import PrivateRoute from "./components/auth/PrivateRoute"
import Login from "./components/auth/Login"
import Home from "./components/home/Home"
import Formations from "./components/formations/Formations"
import SurveysWrapper from "./components/surveys/SurveysWrapper"
import Surveys from "./components/surveys/Surveys"

export default function App() {
    const darkMode = useMediaQuery("(prefers-color-scheme: light)")

    const theme = useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: darkMode ? "dark" : "light",
                },
            }),
        [darkMode]
    )

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <CssBaseline />
                <Switch>
                    <PrivateRoute path="/" exact>
                        <CustomDrawer>
                            <Home />
                        </CustomDrawer>
                    </PrivateRoute>

                    <PrivateRoute path="/formations">
                        <CustomDrawer>
                            <Formations />
                        </CustomDrawer>
                    </PrivateRoute>

                    {/* TODO: This route should be public */}
                    <Route path="/surveys">
                        <SurveysWrapper>
                            <Surveys />
                        </SurveysWrapper>
                    </Route>

                    <Route path="/login">
                        <CustomDrawer>
                            <Login />
                        </CustomDrawer>
                    </Route>

                    <Route path="/logout">{() => <Redirect to="/login" />}</Route>
                    <Route path="*">{() => <h1>404. Try another route</h1>}</Route>
                </Switch>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    )
}
