import { httpGet } from "../../services/api"
import ENDPOINTS from "../../services/endpoints"

export const SET_TEMPLATE_SURVEYS = "SET_TEMPLATE_SURVEYS"

function setTemplateSurveys(templateSurveys) {
    return {
        type: SET_TEMPLATE_SURVEYS,
        templateSurveys,
    }
}

export function handleGetTemplateSurveys() {
    return (dispatch) => {
        return httpGet(ENDPOINTS.templateSurveys)
            .then(({ data }) => dispatch(setTemplateSurveys(data)))
            .catch(() => console.log("handleGetTemplateSurveys(): An error occurred"))
    }
}
